import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, navigate, StaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Swipeable from 'react-swipeable';
import Transition from '../components/transition';
import App from '../components/sky';

import './index.css';

const Header = ({ name, title, date }) => (
  <header>
    <Link to="/1">
      <span>{name}</span> — {title}
    </Link>
    <time>{new Date().toJSON().slice(0,10).replace(/-/g,'/')}</time>
  </header>
);

const isToday = (birthday) => {
  const today = new Date()
  return birthday.getDate() === today.getDate() &&
    birthday.getMonth() === today.getMonth()
}

const today = isToday(new Date('July 7'))

class TemplateWrapper extends Component {

  swipeLeft = () => {
      this.navigate();
  };

  swipeRight = () => {
      this.navigate();
  };

  navigate() {
    if (today) {
      navigate(`/2`);
    } else {
      navigate(`/3`);
    }
  };

  componentDidMount() {
    document.addEventListener('keydown', this.navigate);
    document.body.addEventListener('click', this.navigate); 
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.navigate);
    document.body.removeEventListener('click', this.navigate); 
  }


  render() {
    const { location, children, site } = this.props;

    return (
      <div>
        <Helmet
          title={`${site.siteMetadata.title} — ${site.siteMetadata.name}`}
        />
        <Header
          name={site.siteMetadata.name}
          title={site.siteMetadata.title}
        />
        <Swipeable
          onSwiped ={this.swipe}
          onSwipedLeft={this.swipeLeft}
          onSwipedRight={this.swipeRight}
        >
          <Transition location={location}>
            <div id="slide" style={{'width': '100%'}}>{children}</div>
          </Transition>
        </Swipeable>

      </div>
    );
  }
}

TemplateWrapper.propTypes = {
  children: PropTypes.node,
  data: PropTypes.object,
};

export default props => (
  <StaticQuery
    query={graphql`
      query IndexQuery {
        site {
          siteMetadata {
            name
            title
          }
        }
        allSlide {
          edges {
            node {
              id
            }
          }
        }
      }
    `}
    render={data => (
      <TemplateWrapper
        site={data.site}
        slidesLength={data.allSlide.edges.length}
        {...props}
      />
    )}
  />
);
