import React, { Component } from 'react';
import Sky from 'react-sky';

// you can pass imported images to Sky
import yes0 from "../images/wink.png"
import yes1 from "../images/cheeky.png"
import yes2 from "../images/funnyfunny.png"
import yes3 from "../images/happy.png"
import yes4 from "../images/heart.png"
import yes5 from "../images/me.png"
import yes6 from "../images/peace.png"
import yes7 from "../images/star.png"
import yes8 from "../images/thumbsup.png"


class App extends Component {
  render() {
    return (
      <div>
        <p>This is my super awesome page made with Gatsby!</p>
        <Sky
          images={{
            /* FORMAT AS FOLLOWS */
            0: yes0,
            1: yes1,
            2: yes2,
            3: yes3,
            4: yes4,
            5: yes5,
            6: yes6,
            7: yes7,
            8: yes8
            /* ... */
          }}
          how={130} /* Pass the number of images Sky will render chosing randomly */
          time={40} /* time of animation */
          size={'100px'} /* size of the rendered images */
        />
      </div>
    );
  }
}

export default App;